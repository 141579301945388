exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abit-index-tsx": () => import("./../../../src/pages/abit/index.tsx" /* webpackChunkName: "component---src-pages-abit-index-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-abit-index-tsx": () => import("./../../../src/pages/en/abit/index.tsx" /* webpackChunkName: "component---src-pages-en-abit-index-tsx" */),
  "component---src-pages-en-fuksis-fuksiguide-mint-tsx": () => import("./../../../src/pages/en/fuksis/fuksiguide-mint.tsx" /* webpackChunkName: "component---src-pages-en-fuksis-fuksiguide-mint-tsx" */),
  "component---src-pages-en-fuksis-fuksiguide-tsx": () => import("./../../../src/pages/en/fuksis/fuksiguide.tsx" /* webpackChunkName: "component---src-pages-en-fuksis-fuksiguide-tsx" */),
  "component---src-pages-en-fuksis-index-tsx": () => import("./../../../src/pages/en/fuksis/index.tsx" /* webpackChunkName: "component---src-pages-en-fuksis-index-tsx" */),
  "component---src-pages-en-fuksis-orientationweek-tsx": () => import("./../../../src/pages/en/fuksis/orientationweek.tsx" /* webpackChunkName: "component---src-pages-en-fuksis-orientationweek-tsx" */),
  "component---src-pages-en-fuksis-teekkariculture-tsx": () => import("./../../../src/pages/en/fuksis/teekkariculture.tsx" /* webpackChunkName: "component---src-pages-en-fuksis-teekkariculture-tsx" */),
  "component---src-pages-en-guild-archive-tsx": () => import("./../../../src/pages/en/guild/archive.tsx" /* webpackChunkName: "component---src-pages-en-guild-archive-tsx" */),
  "component---src-pages-en-guild-board-tsx": () => import("./../../../src/pages/en/guild/board.tsx" /* webpackChunkName: "component---src-pages-en-guild-board-tsx" */),
  "component---src-pages-en-guild-confidential-counselors-tsx": () => import("./../../../src/pages/en/guild/confidential-counselors.tsx" /* webpackChunkName: "component---src-pages-en-guild-confidential-counselors-tsx" */),
  "component---src-pages-en-guild-index-tsx": () => import("./../../../src/pages/en/guild/index.tsx" /* webpackChunkName: "component---src-pages-en-guild-index-tsx" */),
  "component---src-pages-en-guild-membership-tsx": () => import("./../../../src/pages/en/guild/membership.tsx" /* webpackChunkName: "component---src-pages-en-guild-membership-tsx" */),
  "component---src-pages-en-guild-officials-tsx": () => import("./../../../src/pages/en/guild/officials.tsx" /* webpackChunkName: "component---src-pages-en-guild-officials-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-partners-index-tsx": () => import("./../../../src/pages/en/partners/index.tsx" /* webpackChunkName: "component---src-pages-en-partners-index-tsx" */),
  "component---src-pages-en-studies-advocacy-tsx": () => import("./../../../src/pages/en/studies/advocacy.tsx" /* webpackChunkName: "component---src-pages-en-studies-advocacy-tsx" */),
  "component---src-pages-en-studies-committee-tips-tsx": () => import("./../../../src/pages/en/studies/committee-tips.tsx" /* webpackChunkName: "component---src-pages-en-studies-committee-tips-tsx" */),
  "component---src-pages-en-studies-index-tsx": () => import("./../../../src/pages/en/studies/index.tsx" /* webpackChunkName: "component---src-pages-en-studies-index-tsx" */),
  "component---src-pages-en-studies-minor-guide-tsx": () => import("./../../../src/pages/en/studies/minor-guide.tsx" /* webpackChunkName: "component---src-pages-en-studies-minor-guide-tsx" */),
  "component---src-pages-fuksis-fuksiguide-mint-tsx": () => import("./../../../src/pages/fuksis/fuksiguide-mint.tsx" /* webpackChunkName: "component---src-pages-fuksis-fuksiguide-mint-tsx" */),
  "component---src-pages-fuksis-fuksiguide-tsx": () => import("./../../../src/pages/fuksis/fuksiguide.tsx" /* webpackChunkName: "component---src-pages-fuksis-fuksiguide-tsx" */),
  "component---src-pages-fuksis-index-tsx": () => import("./../../../src/pages/fuksis/index.tsx" /* webpackChunkName: "component---src-pages-fuksis-index-tsx" */),
  "component---src-pages-fuksis-orientationweek-tsx": () => import("./../../../src/pages/fuksis/orientationweek.tsx" /* webpackChunkName: "component---src-pages-fuksis-orientationweek-tsx" */),
  "component---src-pages-fuksis-teekkariculture-tsx": () => import("./../../../src/pages/fuksis/teekkariculture.tsx" /* webpackChunkName: "component---src-pages-fuksis-teekkariculture-tsx" */),
  "component---src-pages-guild-archive-tsx": () => import("./../../../src/pages/guild/archive.tsx" /* webpackChunkName: "component---src-pages-guild-archive-tsx" */),
  "component---src-pages-guild-board-tsx": () => import("./../../../src/pages/guild/board.tsx" /* webpackChunkName: "component---src-pages-guild-board-tsx" */),
  "component---src-pages-guild-confidential-counselors-tsx": () => import("./../../../src/pages/guild/confidential-counselors.tsx" /* webpackChunkName: "component---src-pages-guild-confidential-counselors-tsx" */),
  "component---src-pages-guild-index-tsx": () => import("./../../../src/pages/guild/index.tsx" /* webpackChunkName: "component---src-pages-guild-index-tsx" */),
  "component---src-pages-guild-membership-tsx": () => import("./../../../src/pages/guild/membership.tsx" /* webpackChunkName: "component---src-pages-guild-membership-tsx" */),
  "component---src-pages-guild-officials-tsx": () => import("./../../../src/pages/guild/officials.tsx" /* webpackChunkName: "component---src-pages-guild-officials-tsx" */),
  "component---src-pages-guild-stimu-tsx": () => import("./../../../src/pages/guild/stimu.tsx" /* webpackChunkName: "component---src-pages-guild-stimu-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-studies-advocacy-tsx": () => import("./../../../src/pages/studies/advocacy.tsx" /* webpackChunkName: "component---src-pages-studies-advocacy-tsx" */),
  "component---src-pages-studies-committee-tips-tsx": () => import("./../../../src/pages/studies/committee-tips.tsx" /* webpackChunkName: "component---src-pages-studies-committee-tips-tsx" */),
  "component---src-pages-studies-index-tsx": () => import("./../../../src/pages/studies/index.tsx" /* webpackChunkName: "component---src-pages-studies-index-tsx" */),
  "component---src-pages-studies-minor-guide-tsx": () => import("./../../../src/pages/studies/minor-guide.tsx" /* webpackChunkName: "component---src-pages-studies-minor-guide-tsx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-contact-index-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/contact/index.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-contact-index-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-contact-index-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/contact/index.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-contact-index-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-badge-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/badge-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-badge-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-code-of-conduct-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/code-of-conduct.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-code-of-conduct-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-electoral-order-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/electoral-order.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-electoral-order-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-excursion-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/excursion-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-excursion-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-flag-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/flag-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-flag-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-guild-rules-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/guild-rules.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-guild-rules-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-investment-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/investment-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-investment-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-overall-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/docs/overall-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-docs-overall-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-documents-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/documents.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-documents-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-harassment-plan-for-equality-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/harassment/plan-for-equality.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-harassment-plan-for-equality-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-harassment-situations-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/harassment/situations.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-harassment-situations-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-ilmokone-en-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/legal/privacy-policy-ilmokone-en.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-ilmokone-en-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-ilmokone-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/legal/privacy-policy-ilmokone.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-ilmokone-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-member-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/legal/privacy-policy-member.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-legal-privacy-policy-member-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-linkwall-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/en/guild/linkwall.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-en-guild-linkwall-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-badge-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/badge-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-badge-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-code-of-conduct-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/code-of-conduct.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-code-of-conduct-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-electoral-order-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/electoral-order.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-electoral-order-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-excursion-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/excursion-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-excursion-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-flag-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/flag-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-flag-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-guild-rules-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/guild-rules.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-guild-rules-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-investment-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/investment-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-investment-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-overall-guidelines-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/docs/overall-guidelines.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-docs-overall-guidelines-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-documents-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/documents.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-documents-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-harassment-plan-for-equality-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/harassment/plan-for-equality.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-harassment-plan-for-equality-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-harassment-situations-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/harassment/situations.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-harassment-situations-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-legal-privacy-policy-ilmokone-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/legal/privacy-policy-ilmokone.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-legal-privacy-policy-ilmokone-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-legal-privacy-policy-member-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/legal/privacy-policy-member.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-legal-privacy-policy-member-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-linkwall-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/github/workspace/src/mdx-pages/guild/linkwall.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-mdx-pages-guild-linkwall-mdx" */)
}

